<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="4">
          <FormulateInput
            id="store-name"
            v-model="filters.storeId"
            type="vue-select"
            :label="$t('Loja')"
            :placeholder="$t('Todas')"
            :options="storeOptions"
          />
        </b-col>
        <b-col md="4">
          <e-search-sku
            id="product-name"
            v-model="filters.skuId"
            :required="false"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="invoice-number"
            v-model="filters.invoiceNumber"
            type="text"
            :label="$t('Nota Fiscal')"
            :placeholder="$t('Nota Fiscal')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="sale-id"
            v-model="filters.saleOrPurchaseOrderId"
            type="text"
            :label="$t('Id do Pedido/Venda')"
            :placeholder="$t('Id do Pedido/Venda')"
            :instruction="$t('Id do pedido de compra ou da venda')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="type"
            v-model="filters.movementType"
            type="vue-select"
            :label="$t('Tipo')"
            :placeholder="$t('Todos')"
            :options="stockMovementTypes"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            v-model="filters.movementOrigin"
            type="vue-select"
            :label="$t('Origem')"
            :placeholder="$t('Todos')"
            :options="stockMovementOrigins"
          />
        </b-col>
        <b-col md="5">
          <FormulateInput
            v-model="filters.period"
            type="date-range-picker"
            :label="$t('Período')"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="tablestockMovements"
        responsive
        show-empty
        striped
        class="mb-1 bordered"
        :empty-text="getEmptyTableMessage($tc('STOCK_MOVEMENT_REASON.NAME'), 'female')"
        primary-key="id"
        :fields="fields"
        :items="stockMovements"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(movementType)="row">
          <div>
            <e-instructions
              :title="$t('Informações adicionais')"
              :target="`instruction_stock_${row.item.id}`"
              :instructions="instructions(row.item)"
            />

            {{ $t(row.item.movementType) }}
          </div>
        </template>

        <template #cell(movementOrigin)="row">
          <span> {{ stockMovementOriginLabel(row.item).label }}</span>

          <e-router-link
            v-if="stockMovementOriginLabel(row.item).routerName"
            :to="{
              name: stockMovementOriginLabel(row.item).routerName,
              params: stockMovementOriginLabel(row.item).routerParams,
            }"
            :value="stockMovementOriginLabel(row.item).value"
          />
        </template>
        <template #cell(skuName)="{ item }">
          <e-product-router-link
            :sku-id="item.sku.id"
            :product-id="item.sku.productId"
            :product-name="item.sku.name"
            :ean="item.sku.ean"
          />
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="user-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="$can('ManualUpdate', 'StockMovement')"
      :main-tooltip="$t('Adicionar Movimentação')"
      @click="showCreate"
    />
  </div>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import {
  EPageSizeSelector,
  EPaginationSummary,
  ESearchSku,
  EProductRouterLink,
  EFilters,
  ERouterLink,
  EInstructions,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { formulateValidation } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    ERouterLink,
    BPagination,
    BTable,
    EPaginationSummary,
    EInstructions,
    EPageSizeSelector,
    fab,
    EFilters,
    ESearchSku,
    EProductRouterLink,
  },

  mixins: [formulateValidation],

  data() {
    return {
      fetching: false,
      saving: false,
      skus: [],
    }
  },

  computed: {
    ...mapGetters('app', ['storeOptions']),
    ...mapState('pages/stock/stockMovement', ['stockMovements', 'sorting', 'paging', 'filters']),
    ...mapGetters('pages/stock/stockMovement', ['stockMovementTypes', 'stockMovementOrigins']),
    skusOptions() {
      return this.skus.map(sku => ({
        ...sku,
        value: sku.id,
        label: sku.name,
      }))
    },
    fields() {
      return [
        {
          label: this.$t('Data/Hora'),
          key: 'movementDate',
          class: 'text-center',
          thStyle: { width: '140px' },
          sortable: true,
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Tipo'),
          key: 'movementType',
          class: 'text-center',
        },
        {
          label: this.$t('Loja'),
          key: 'store.name',
          thStyle: { width: '250px' },
          formatter: (val, key, item) => this.$options.filters.storeName(item.store),
        },
        {
          label: this.$t('Produto'),
          key: 'skuName',
          sortKey: 'sku.name',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Qtd.'),
          key: 'stockMovement',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
          formatter: value => this.$options.filters.number(value, 3, true),
        },
        {
          label: this.$t('Novo Estoque'),
          key: 'newStock',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
          formatter: value => this.$options.filters.number(value, 3, true),
        },
        {
          label: this.$t('Origem'),
          key: 'movementOrigin',
          class: 'text-center',
          thStyle: { width: '180px' },
        },
      ]
    },

    today() {
      return new Date()
    },
  },

  mounted() {
    if (
      this.$route.query?.storeId ||
      this.$route.query?.skuId ||
      this.$route.query?.startDate ||
      this.$route.query?.endDate
    ) {
      this.setFilters({
        period: {
          startDate: this.$route.query?.startDate,
          endDate: this.$route.query?.endDate,
        },
        storeId: this.$route.query?.storeId?.toString(),
        skuId: this.$route.query?.skuId?.toString(),
      })
    }
    // else {
    //   this.setFilters(this.filters)
    // }
    this.getData()
  },

  methods: {
    ...mapActions('pages/stock/stockMovement/stockMovementMaintain', {
      stCleanStateStockMovementMaintain: 'cleanState',
    }),
    ...mapActions('pages/stock/stockMovement', [
      'fetchStockMovements',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
      'setFilters',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchStockMovements()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    instructions(item) {
      const results = [
        {
          text: `${this.$t('Perdas e Avarias')}: ${
            item.lossesAndDamages ? this.$t('Sim') : this.$t('Não')
          }`,
        },
      ]
      if (item.comments) {
        results.push({
          text: `${this.$t('Obs')}: ${this.$t(item.comments)}`,
        })
      }
      if (item.movementOrigin === 'Manual') {
        results.push({
          text: `${this.$t('Responsável')}: ${item.createdBy}`,
        })
      }

      if (item.movementReason) {
        results.push({
          text: `${this.$t('Motivo')}: ${this.$t(item.movementReason.name)} ${
            item.movementReason.description ? `(${this.$t(item.movementReason.description)})` : ''
          }`,
        })
      }
      return results
    },
    stockMovementOriginLabel(item) {
      const config = () => {
        switch (item.movementOrigin) {
          case 'Invoice':
            return {
              value: item.invoiceNumber,
              routerName: 'invoice-maintain',
              routerParams: { id: item.invoiceId },
            }
          case 'PurchaseOrder':
            return {
              value: item.purchaseOrder.id,
              routerName: 'purchase-order-details',
              routerParams: { id: item.purchaseOrderId },
            }
          case 'Sale':
          case 'SaleReclaim':
          case 'SaleItemReturn':
            return {
              value: item.saleId,
              routerName: 'order-read-only',
              routerParams: { id: item.saleId },
            }
          case 'Inventory':
            return {
              value: item.stockInventoryId,
              routerName: 'stock-inventory-maintain',
              routerParams: { id: item.stockInventoryId },
            }
          default:
            return {}
        }
      }

      return {
        label: this.stockMovementOrigins.find(i => i.value === item.movementOrigin)?.label,
        value: config().value,
        routerName: config().routerName,
        routerParams: config().routerParams,
      }
    },
    showCreate() {
      this.stCleanStateStockMovementMaintain()
      this.$router.push({ name: 'stock-movement-add' })
    },

    async showUpdateBrandSidebar(row) {
      this.$router.push({ name: 'stock-movement-maintain', params: { id: row.id } })
    },
  },
}
</script>

<style lang="scss" scoped></style>
