var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "store-name",
                      type: "vue-select",
                      label: _vm.$t("Loja"),
                      placeholder: _vm.$t("Todas"),
                      options: _vm.storeOptions,
                    },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-search-sku", {
                    attrs: { id: "product-name", required: false },
                    model: {
                      value: _vm.filters.skuId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "skuId", $$v)
                      },
                      expression: "filters.skuId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-number",
                      type: "text",
                      label: _vm.$t("Nota Fiscal"),
                      placeholder: _vm.$t("Nota Fiscal"),
                    },
                    model: {
                      value: _vm.filters.invoiceNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "invoiceNumber", $$v)
                      },
                      expression: "filters.invoiceNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "sale-id",
                      type: "text",
                      label: _vm.$t("Id do Pedido/Venda"),
                      placeholder: _vm.$t("Id do Pedido/Venda"),
                      instruction: _vm.$t("Id do pedido de compra ou da venda"),
                    },
                    model: {
                      value: _vm.filters.saleOrPurchaseOrderId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "saleOrPurchaseOrderId", $$v)
                      },
                      expression: "filters.saleOrPurchaseOrderId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "type",
                      type: "vue-select",
                      label: _vm.$t("Tipo"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.stockMovementTypes,
                    },
                    model: {
                      value: _vm.filters.movementType,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "movementType", $$v)
                      },
                      expression: "filters.movementType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      type: "vue-select",
                      label: _vm.$t("Origem"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.stockMovementOrigins,
                    },
                    model: {
                      value: _vm.filters.movementOrigin,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "movementOrigin", $$v)
                      },
                      expression: "filters.movementOrigin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "5" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      type: "date-range-picker",
                      label: _vm.$t("Período"),
                    },
                    model: {
                      value: _vm.filters.period,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "period", $$v)
                      },
                      expression: "filters.period",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "tablestockMovements",
            staticClass: "mb-1 bordered",
            attrs: {
              responsive: "",
              "show-empty": "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("STOCK_MOVEMENT_REASON.NAME"),
                "female"
              ),
              "primary-key": "id",
              fields: _vm.fields,
              items: _vm.stockMovements,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(movementType)",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      [
                        _c("e-instructions", {
                          attrs: {
                            title: _vm.$t("Informações adicionais"),
                            target: "instruction_stock_" + row.item.id,
                            instructions: _vm.instructions(row.item),
                          },
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$t(row.item.movementType)) + " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(movementOrigin)",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.stockMovementOriginLabel(row.item).label)
                      ),
                    ]),
                    _vm.stockMovementOriginLabel(row.item).routerName
                      ? _c("e-router-link", {
                          attrs: {
                            to: {
                              name: _vm.stockMovementOriginLabel(row.item)
                                .routerName,
                              params: _vm.stockMovementOriginLabel(row.item)
                                .routerParams,
                            },
                            value: _vm.stockMovementOriginLabel(row.item).value,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(skuName)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("e-product-router-link", {
                      attrs: {
                        "sku-id": item.sku.id,
                        "product-id": item.sku.productId,
                        "product-name": item.sku.name,
                        ean: item.sku.ean,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "user-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("ManualUpdate", "StockMovement")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Movimentação") },
            on: { click: _vm.showCreate },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }